import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PrimeReactProvider } from "primereact/api";
import Route from "./routes/index";
import "primereact/resources/themes/lara-light-cyan/theme.css";
function App() {
  return (
    <div>
     
    </div>
  );
}

export default App;
